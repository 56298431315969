<template>
  <div
    id="app"
    class="wrapper"
  >
    <div class="intro intro--divided">
      <error-popup
        title="Oops !"
        :message="errorMessage"
        button-text="Fermer"
        :open="errorPopupOpen"
        @close="currentError = ERROR_NONE"
      />
      <intro-content
        :title="''"
        :srcset="secondaryLogoSrcSet"
        :logo="secondaryLogoSrc"
      />
      <aside class="intro__aside">
        <header class="intro__aside__header">
          <a
            href="#"
            class="btn-back"
            @click.prevent="$router.back()"
          >
            <img
              src="../assets/images/icons/ico-arrow-left.svg"
              width="8"
              height="13"
              alt="back button"
            >
            {{ $t(`views.login.texts.${$route.name}`) }}
          </a>
        </header>
        <form
          action="/"
          method="post"
          class="form form--light"
        >
          <div
            v-if="success === true"
            class="form__row"
          >
            Consultez vos emails ! un email contenant un lien de connexion vient de vous être envoyé
          </div>
          <div
            v-if="success === false"
            class="form__row"
          >
            {{ $t('views.loginGame.intro') }}
          </div>
          <div
            v-if="success === false"
            class="form__row"
          >
            <text-field
              v-model="state.email"
              field-id="email"
              :field-label="$t('views.login.fields.email')"
              field-type="email"
            />
          </div>

          <footer
            v-if="success === false"
            class="form__footer"
          >
            <div class="form__actions">
              <br>
              <button
                type="submit"
                class="btn btn--block btn--red"
                @click.prevent="submitForm"
              >
                {{ $t("views.loginGame.loginBtn") }}
              </button>
              <div
                v-if="appConfig.enableRegistration === true"
              >
                <span class="form__actions__separator">{{
                  $t("views.login.texts.no_account")
                }}</span>
                <button
                  type="submit"
                  class="btn btn--block btn--light"
                  @click.prevent="redirectToRegister"
                >
                  {{ $t("views.login.buttons.register") }}
                </button>
              </div>
            </div>
          </footer>
        </form>
      </aside>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, reactive } from 'vue';
import { required, email } from '@vuelidate/validators';
import { useVuelidate} from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import TextField from '../components/FormTextField.vue';
import IntroContent from '../components/IntroContent.vue';
import { useCompanyLogo } from '../composables/CompanyLogo';
import GameAPI from '../api/games';
import ErrorPopup from '../components/ErrorPopup.vue';
import { useConfigStore } from '../pinia/configuration';
import { useGameStore } from '../pinia/game';

const ERROR_NONE = '';
const ERROR_NO_GAME = 'noGame';
const ERROR_SERVER = 'server';
const ERROR_EMPTY = 'empty form';
const ERROR_FORM = 'invalid form';
const { secondaryLogoSrc, secondaryLogoSrcSet } = useCompanyLogo();
const appConfig = useConfigStore().appConfig;
const router = useRouter();
const gameStore = useGameStore();

const { t } = useI18n();



const rules = {
  email: {
    required,
    email,
  },
};

const errorPopupOpen = computed(() => currentError.value !== ERROR_NONE);
const currentError = ref(ERROR_NONE);
const success = ref(false);
const state = reactive({
  email: ''
});
const v$ = useVuelidate(rules, state);
const errorMessage = computed(() => {
  switch (currentError.value) {
    case ERROR_FORM:
      return t('views.loginGame.errorForm');
    case ERROR_SERVER:
      return t('views.loginGame.serverError');
    case ERROR_EMPTY:
      return t('views.loginGame.empty');
    case ERROR_NO_GAME:
      return t('views.loginGame.noGame');
    default:
      return '';
  }
});

const redirectToRegister = () =>  {
      router.push({ name: 'Register' });
    };
    const submitForm = async () =>  {
      success.value = false;

      const res = await v$.value.$validate()
      if (!res) {
        currentError.value = ERROR_FORM;
        v$.value.$errors.forEach((error) => {
          console.log(error);
        });
      return
      }
      await doLogin();

    };
    const doLogin = async () => {
      try {
        currentError.value = ERROR_NONE;
        const gamesList = await GameAPI.getGames(appConfig.gameApiBaseUrl, currentDestination.value.uuid, 'started');
        if (!Array.isArray(gamesList) || gamesList.length !== 1) {
          currentError.value = ERROR_NO_GAME;
          return;
        }
        await gameStore.resendWelcomeEmail(gamesList[0].id, v$.value.email.$model);
        success.value = true;
      } catch (error) {
        console.log(error);
        success.value = false;
        currentError.value = ERROR_SERVER;
      }

    };
</script>

<style scoped>
.errors {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: rgba(218, 0, 75, 0.6);
}
</style>
